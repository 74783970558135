import Konva from 'konva';

import { Coordinates } from '../../types';

type Options = { colorPrimary: string; opacity: number; size: number };

const circle = new Konva.Circle({
  listening: false,
});

export class ScatterGlyph {
  shape: Konva.Group;
  size: number;
  colorPrimary: string;

  constructor({ colorPrimary, opacity, size }: Options) {
    this.shape = new Konva.Group({ opacity });
    this.size = size;
    this.colorPrimary = colorPrimary;
  }

  update(coordinates: Coordinates[], scaleFactor: number) {
    this.shape.destroyChildren();

    coordinates.forEach((coordinate) => {
      if (coordinate) {
        this.shape.add(
          circle.clone({
            fill: this.colorPrimary,
            radius: this.size * scaleFactor,
            x: coordinate.x * scaleFactor,
            y: coordinate.y * scaleFactor,
          }),
        );
      }
    });
  }
}
