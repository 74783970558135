import { Colors } from 'kognia-ui';
import Konva from 'konva';

import { Coordinates } from '../../types';
import { createSemicircle } from '../assets';

type Options = {
  colorPrimary: Colors;
  colorSecondary: Colors;
  opacity: number;
  size: number;
  rotation: number;
  imageInterface: unknown;
};

export class SemiCircleGlyph {
  shape: Konva.Image;
  size: number;

  constructor({ colorPrimary, colorSecondary, opacity, size, rotation, imageInterface }: Options) {
    this.size = size;

    this.shape = new Konva.Image({
      opacity: opacity,
      width: size,
      height: size,
      image: createSemicircle(colorPrimary, colorSecondary, imageInterface),
      rotation: rotation,
    });
  }

  update(coordinates: Coordinates, scaleFactor: number) {
    const adjustedSize = this.size * scaleFactor;
    this.shape.width(adjustedSize);
    this.shape.height(adjustedSize);
    this.shape.offsetX(adjustedSize / 2);
    this.shape.offsetY(adjustedSize / 2);
    this.shape.x(coordinates.x * scaleFactor);
    this.shape.y(coordinates.y * scaleFactor);
  }
}
