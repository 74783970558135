import { Events } from '../types';

interface LoggerParams {
  type: keyof typeof Events;
}

export function LogEvent({ type }: LoggerParams) {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const original = descriptor.value;

    descriptor.value = function (...args: any[]) {
      const detail = args.length > 0 ? args.map((value) => value) : '';

      if (process.env.NODE_ENV === 'development') {
        //console.log(`%c [OVERLAY_GENERATOR]`, 'color: orange', type, detail);
      }

      return original.apply(this, args);
    };
  };
}

export function Time() {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const original = descriptor.value;

    descriptor.value = function (...args: any[]) {
      if (process.env.NODE_ENV !== 'development') return original.apply(this, args);

      //console.time(propertyKey);
      const result = original.apply(this, args);
      //console.timeEnd(propertyKey);
      return result;
    };
  };
}
