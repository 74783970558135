import { keyframes } from '@mui/material';
import { Colors } from './colors';

export const animationDurations = {
  fast: '0.3s',
  medium: '0.6s',
  long: '0.9s',
  veryLong: '1.8s',
};

export const animations = {
  fadeIn: keyframes`
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  `,
  fadeOut: keyframes`
    0% {
      opacity: 1;
    }

    100% {
      opacity: 100;
    }
  `,
  loading: keyframes`
    0% {
      background-color: ${Colors.athens};
    }

    50% {
      background-color: ${Colors.ghost};
    }

    100% {
      background-color: ${Colors.athens};
    }`,
  spin: keyframes`
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  `,
};
