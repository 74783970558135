import Konva from 'konva';
import { Coordinates, OverlayElement, PitchSize } from '../../types';

import { Reference, Segment } from '../../utils/loaders';
import { HighlightGlyph } from '../glyphs/HighlightGlyph';
import { PolygonGlyph } from '../glyphs/PolygonGlyph';
import { OverlayElementGlyphs, UpdatePositionOptions } from './interface';
import {
  defensiveLinePitchSide,
  findPlayersCoordinates,
  getCurrentSegment,
  getPlayerTeam,
  isFrameInRange,
} from './utils';

type Options = {
  references: Reference[];
  overlayElementTypeId: string;
  startFrame: number;
  endFrame: number;
  config: OverlayElement;
  imageInterface: unknown;
};

const findPlayerWithMinOrMaxY = (playersCoordinates: Record<string, Coordinates>, type: 'min' | 'max'): string => {
  let resultY = type === 'min' ? 99999 : -1;
  let resultPlayerId = '';

  Object.keys(playersCoordinates).forEach((playerId) => {
    if (
      (type === 'min' && playersCoordinates[playerId].y < resultY) ||
      (type === 'max' && playersCoordinates[playerId].y > resultY)
    ) {
      resultY = playersCoordinates[playerId].y;
      resultPlayerId = playerId;
    }
  });

  return resultPlayerId;
};

export class DefensiveLinePlayerDepthHighlight extends OverlayElementGlyphs {
  defenseArea: PolygonGlyph;
  overlayElementTypeId: string;
  players: HighlightGlyph[] = [];
  references: Reference[];
  startFrame: number;
  endFrame: number;
  priority: number;
  excludedPlayerIdsFromRender: string[] = [];
  isVisible: boolean = true;

  constructor({ references, overlayElementTypeId, startFrame, endFrame, config, imageInterface }: Options) {
    super();
    // TODO should we do this more explicit in config?
    const playerGlyphConfig = config.glyphs[0];
    const polygonGlyphConfig = config.glyphs[1];

    this.defenseArea = new PolygonGlyph({
      colorPrimary: polygonGlyphConfig.colorPrimary,
      opacity: polygonGlyphConfig.alpha ?? 1,
      size: polygonGlyphConfig.size,
    });
    this.overlayElementTypeId = overlayElementTypeId;
    this.references = references;
    this.startFrame = startFrame;
    this.endFrame = endFrame;
    this.priority = config.priority;

    this.references.forEach((reference) => {
      if (reference.referenceType === 'players') {
        this.players = reference.values.map((playerId) => {
          return new HighlightGlyph({
            playerId: playerId,
            colorPrimary: playerGlyphConfig.colorPrimary,
            colorSecondary: playerGlyphConfig.colorSecondary,
            opacity: playerGlyphConfig.alpha ?? 1,
            size: playerGlyphConfig.size,
            startX: 0,
            startY: 0,
            type: 'dashed',
            imageInterface,
          });
        });
      }
    });
  }

  addToLayer(layer: Konva.Layer) {
    layer.add(this.defenseArea.shape);
    this.players.forEach((player) => {
      layer.add(player.shape);
    });
  }

  update({ playersPositions, scale, frame, pitchSize, segments, teams }: UpdatePositionOptions) {
    if (!isFrameInRange(frame, this) || !this.players[0]) return;

    const teamId = getPlayerTeam(this.players[0].playerId, teams);
    const segment = getCurrentSegment(frame, segments);

    const playersCoordinates = findPlayersCoordinates(
      this.players.map((player) => player.playerId),
      playersPositions[frame],
    );

    const firstPlayerInLine = playersCoordinates[findPlayerWithMinOrMaxY(playersCoordinates, 'min')];
    const lastPlayerInLine = playersCoordinates[findPlayerWithMinOrMaxY(playersCoordinates, 'max')];
    const minXPos = this.getXPositionForLastPlayerInLine(playersCoordinates, pitchSize, teamId, segment);

    try {
      this.players.forEach((player) => {
        if (playersCoordinates[player.playerId]) {
          this.excludedPlayerIdsFromRender.includes(player.playerId) ? player.shape.hide() : player.shape.show();

          player.update(playersCoordinates[player.playerId].x, playersCoordinates[player.playerId].y, scale);
        }
      });

      const topLineVertices = [
        { x: minXPos, y: 0 },
        { x: firstPlayerInLine.x, y: 0 },
      ];
      const bottomLineVertices = [
        { x: lastPlayerInLine.x, y: pitchSize.width },
        { x: minXPos, y: pitchSize.width },
      ];
      const playersVertices = this.players
        .map((player) => playersCoordinates[player.playerId])
        .filter((player) => player?.x);

      this.defenseArea.update([...topLineVertices, ...playersVertices, ...bottomLineVertices], scale);
    } catch (e) {}
  }

  removeFromLayer = () => {
    this.players.forEach((player) => player.shape.destroy());
    this.defenseArea.shape.destroy();
  };

  show = () => {
    this.players.forEach((player) => player.shape.show());
    this.defenseArea.shape.show();
    this.isVisible = true;
  };

  hide = () => {
    this.players.forEach((player) => player.shape.hide());
    this.defenseArea.shape.hide();
    this.isVisible = false;
  };

  setExcludedPlayerIdsFromRender(references: string[]) {
    this.excludedPlayerIdsFromRender = references;
  }

  private getXPositionForLastPlayerInLine(
    playersCoordinates: { [key in number]: Coordinates },
    pitchSize: PitchSize,
    teamId: string,
    segment?: Segment,
  ): number {
    const playersXPositions = Object.values(playersCoordinates).map((player) => player.x);

    return defensiveLinePitchSide(playersXPositions, pitchSize, teamId, segment) === 'left'
      ? Math.min(...playersXPositions)
      : Math.max(...playersXPositions);
  }
}
