import Konva from 'konva';
import { GlyphElement, OverlayElement } from '../../types';

import { Reference } from '../../utils/loaders';
import { ArrowGlyph } from '../glyphs/ArrowGlyph';
import { OverlayElementGlyphs, UpdatePositionOptions } from './interface';
import { findPlayersCoordinates, getPlayerReferences, isFrameInRange } from './utils';

type Options = {
  config: OverlayElement;
  overlayElementTypeId: string;
  references: Reference[];
  startFrame: number;
  endFrame: number;
};

export class LineWithArrow extends OverlayElementGlyphs {
  arrow: ArrowGlyph;
  overlayElementTypeId: string;
  playersIds: string[];
  references: Reference[];
  startFrame: number;
  endFrame: number;
  priority: number;
  excludedPlayerIdsFromRender: string[] = [];
  isVisible: boolean = true;

  constructor({ config, endFrame, overlayElementTypeId, references, startFrame }: Options) {
    super();
    // TODO should we do this more explicit in config?
    const lineGlyphConfig = config.glyphs[0];

    this.arrow = new ArrowGlyph({
      opacity: lineGlyphConfig.alpha ?? 1,
      size: lineGlyphConfig.size,
      colorPrimary: lineGlyphConfig.colorPrimary,
      dashed: lineGlyphConfig.element === GlyphElement.DASHED_LINE,
    });

    this.endFrame = endFrame;
    this.overlayElementTypeId = overlayElementTypeId;
    this.references = references;
    this.playersIds = getPlayerReferences(this.references);
    this.startFrame = startFrame;
    this.priority = config.priority;
  }

  addToLayer(layer: Konva.Layer) {
    layer.add(this.arrow.shape);
  }

  update({ playersPositions, scale, frame }: UpdatePositionOptions) {
    if (!isFrameInRange(frame, this)) return;

    const firstPlayer = findPlayersCoordinates([this.playersIds[0]], playersPositions[frame]);
    const lastPlayer = findPlayersCoordinates([this.playersIds[1]], playersPositions[this.endFrame]);

    if (firstPlayer[this.playersIds[0]] && lastPlayer[this.playersIds[1]]) {
      // TODO check why sometimes lastPlayer is undefined
      const excludedPlayerIds = this.excludedPlayerIdsFromRender;

      //TODO fix how priority is handled (sometimes is 1 and sometimes is 0)
      excludedPlayerIds.includes(this.playersIds[1]) && excludedPlayerIds.includes(this.playersIds[0])
        ? this.hide()
        : this.show();
      this.arrow.update([firstPlayer[this.playersIds[0]], lastPlayer[this.playersIds[1]]], scale);
    }
  }

  removeFromLayer = () => {
    this.arrow.shape.destroy();
  };

  show = () => {
    this.arrow.shape.show();
    this.isVisible = true;
  };

  hide = () => {
    this.arrow.shape.hide();
    this.isVisible = false;
  };

  setExcludedPlayerIdsFromRender(references: string[]) {
    this.excludedPlayerIdsFromRender = references;
  }
}
