import { TacticId } from '@kognia/tactical-analysis-data';

import { OverlayTactic } from '../utils/loaders';
import { OverlayElementGlyphs } from './overlay-elements/interface';

export const findExcludedReferences = (overlayElements: OverlayElementGlyphs[]) => {
  const minPriority = Math.min(...overlayElements.map((c) => c.priority));
  const conflictingOverlayElementWithMinPriority = overlayElements.find((c) => c.priority === minPriority);
  if (conflictingOverlayElementWithMinPriority) {
    return conflictingOverlayElementWithMinPriority.references;
  }

  return [];
};

export function findOverlayTacticsByFrame(frame: number, overlayTactics: OverlayTactic[]) {
  return overlayTactics
    .filter((overlayTactic: OverlayTactic) => {
      return frame >= overlayTactic.startFrame && frame <= overlayTactic.endFrame;
    })
    .filter((t) => t.overlayElements.some((e) => frame >= e.startFrame && frame <= e.endFrame));
}

export function filterOverlayTacticsByTacticId(overlayTactics: OverlayTactic[], tactics?: TacticId[]) {
  return overlayTactics.filter((overlayElement) => !tactics || tactics.includes(overlayElement.tacticTypeId));
}

export function filterOverlayTactics(frame: number, overlayTactics: OverlayTactic[], tactics?: TacticId[]) {
  const activeOverlayTactics = findOverlayTacticsByFrame(frame, overlayTactics);

  return {
    availableOverlayTactics: activeOverlayTactics,
    filteredOverlayTactics: filterOverlayTacticsByTacticId(activeOverlayTactics, tactics),
  };
}
