import Konva from 'konva';
import { OverlayElement } from '../../types';

import { Reference } from '../../utils/loaders';
import { CircleAreaGlyph } from '../glyphs/CircleAreaGlyph';
import { OverlayElementGlyphs, UpdatePositionOptions } from './interface';
import { getPlayerReferences, isFrameInRange } from './utils';

type Options = {
  config: OverlayElement;
  overlayElementTypeId: string;
  references: Reference[];
  startFrame: number;
  endFrame: number;
};

function getSizeFromPercentage(initialValue: number, finalValue: number, percentage: number) {
  return ((finalValue - initialValue / 100) * percentage + initialValue) / 100;
}

function getPercentageFromRange(initialValue: number, finalValue: number, currentValue: number) {
  return ((currentValue - initialValue) / (finalValue - initialValue)) * 100;
}

export class PlayerCircleAreaHighlight extends OverlayElementGlyphs {
  circle: CircleAreaGlyph;
  finalSize: number = 0;
  initialSize: number = 0;
  overlayElementTypeId: string;
  playerId: string;
  references: Reference[];
  startFrame: number;
  endFrame: number;
  priority: number;
  excludedPlayerIdsFromRender: string[] = [];
  isVisible: boolean = true;

  constructor({ config, endFrame, overlayElementTypeId, references, startFrame }: Options) {
    super();
    // TODO should we do this more explicit in config?
    const lineGlyphConfig = config.glyphs[0];

    this.circle = new CircleAreaGlyph({
      opacity: lineGlyphConfig.alpha ?? 1,
      size: lineGlyphConfig.size,
      colorPrimary: lineGlyphConfig.colorPrimary,
    });

    this.initialSize = lineGlyphConfig.initial_size ?? 1;
    this.finalSize = lineGlyphConfig.final_size ?? 1;
    this.overlayElementTypeId = overlayElementTypeId;
    this.references = references;
    this.playerId = getPlayerReferences(this.references)[0];
    this.startFrame = startFrame;
    this.endFrame = endFrame;
    this.priority = config.priority;
  }

  addToLayer(layer: Konva.Layer) {
    layer.add(this.circle.shape);
  }

  update({ playersPositions, scale, frame, pitchSize, teams }: UpdatePositionOptions) {
    if (!isFrameInRange(frame, this)) return;

    const framePlayers = playersPositions[frame];
    const percentage = getPercentageFromRange(this.startFrame, this.endFrame, frame);
    const size = getSizeFromPercentage(this.initialSize, this.finalSize, percentage);

    if (!framePlayers) console.warn(`No trajectories for frame ${frame} in overlay element CircleAreaHighlight`);

    if (framePlayers && framePlayers[this.playerId]) {
      this.excludedPlayerIdsFromRender.includes(this.playerId) ? this.hide() : this.show();

      this.circle.update(size, framePlayers[this.playerId], scale);
    }
  }

  removeFromLayer = () => {
    this.circle.shape.destroy();
  };

  show = () => {
    this.circle.shape.show();
    this.isVisible = true;
  };

  hide = () => {
    this.circle.shape.hide();
    this.isVisible = false;
  };

  setExcludedPlayerIdsFromRender(references: string[]) {
    this.excludedPlayerIdsFromRender = references;
  }
}
