import Konva from 'konva';
import { OverlayElement } from '../../types';

import { Reference } from '../../utils/loaders';
import { HighlightGlyph } from '../glyphs/HighlightGlyph';
import { OverlayElementGlyphs, UpdatePositionOptions } from './interface';
import { findPlayersCoordinates, getPlayerReferences, isFrameInRange } from './utils';

type Options = {
  config: OverlayElement;
  overlayElementTypeId: string;
  references: Reference[];
  startFrame: number;
  endFrame: number;
  imageInterface: unknown;
};

export class HighlightCirclePlayer extends OverlayElementGlyphs {
  players: HighlightGlyph[];
  overlayElementTypeId: string;
  playersIds: string[];
  references: Reference[];
  startFrame: number;
  endFrame: number;
  priority: number;
  excludedPlayerIdsFromRender: string[] = [];
  isVisible: boolean = true;

  constructor({ config, endFrame, overlayElementTypeId, references, startFrame, imageInterface }: Options) {
    super();
    // TODO should we do this more explicit in config?
    const highlightGlyphConfig = config.glyphs[0];
    const playersIds = getPlayerReferences(references);

    this.endFrame = endFrame;
    this.overlayElementTypeId = overlayElementTypeId;
    this.references = references;
    this.startFrame = startFrame;
    this.playersIds = getPlayerReferences(this.references);
    this.priority = config.priority;

    this.players = playersIds.map(
      (playerId) =>
        new HighlightGlyph({
          colorPrimary: highlightGlyphConfig.colorPrimary,
          colorSecondary: highlightGlyphConfig.colorSecondary,
          opacity: highlightGlyphConfig.alpha ?? 1,
          playerId: playerId,
          size: highlightGlyphConfig.size,
          startX: 0,
          startY: 0,
          type: highlightGlyphConfig.style === 'circle' ? 'circle' : 'dashed',
          imageInterface,
        }),
    );
  }

  addToLayer(layer: Konva.Layer) {
    this.players.forEach((player) => layer.add(player.shape));
  }

  update({ playersPositions, scale, frame }: UpdatePositionOptions) {
    if (!isFrameInRange(frame, this)) return;

    const referencesToExclude = this.excludedPlayerIdsFromRender;
    const playersCoordinates = findPlayersCoordinates(this.playersIds, playersPositions[frame]);

    this.players.forEach((player) => {
      if (referencesToExclude.includes(player.playerId) || !playersCoordinates[player.playerId]) {
        player.shape.hide();
      } else {
        player.shape.show();
        player.update(playersCoordinates[player.playerId].x, playersCoordinates[player.playerId].y, scale);
      }
    });
  }

  removeFromLayer = () => {
    this.players.forEach((player) => player.shape.destroy());
  };

  show = () => {
    this.players.forEach((player) => player.shape.show());
    this.isVisible = true;
  };

  hide = () => {
    this.players.forEach((player) => player.shape.hide());
    this.isVisible = false;
  };

  setExcludedPlayerIdsFromRender(references: string[]) {
    this.excludedPlayerIdsFromRender = references;
  }
}
