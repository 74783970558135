import Konva from 'konva';

import { Coordinates } from '../../types';

type Options = { colorPrimary: string; opacity: number; size: number };

const circle = new Konva.Circle({
  listening: false,
});

export class CircleAreaGlyph {
  shape: Konva.Circle;

  constructor({ colorPrimary, opacity }: Options) {
    this.shape = circle.clone({
      fill: colorPrimary,
      opacity: opacity,
    });
  }

  update(size: number, coordinates: Coordinates, scaleFactor: number) {
    const adjustedSize = size * scaleFactor;
    this.shape.radius(adjustedSize);
    this.shape.x(coordinates.x * scaleFactor);
    this.shape.y(coordinates.y * scaleFactor);
  }
}
