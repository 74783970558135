import { Colors } from 'kognia-ui';

export const semiCircleSvg = (colorPrimary: Colors, colorSecondary: Colors) => `
<svg xmlns="http://www.w3.org/2000/svg" width="343" height="343" fill="none" >
  <g stroke-width="23.386">
    <path d="M171.5 11.6927C213.883 11.6927 254.531 28.5294 284.501 58.499C314.47 88.4686 331.307 129.116 331.307 171.5C331.307 213.883 314.47 254.53 284.5 284.5C254.531 314.47 213.883 331.306 171.5 331.306" stroke="url(#A)"/>
    <circle cx="171.497" cy="171.5" r="100.042" stroke="url(#B)"/>
  </g>
  <defs>
    <linearGradient id="A" x1="343" y1="162.473" x2="0" y2="162.473" gradientUnits="userSpaceOnUse">
      <stop stop-color="${colorPrimary}"/>
      <stop offset=".438" stop-color="${colorPrimary}" stop-opacity="0"/>
      <stop offset=".604" stop-color="${colorPrimary}" stop-opacity="0"/>
      <stop offset="1" stop-color="${colorPrimary}"/>
    </linearGradient>
    <linearGradient id="B" x1="59.762" y1="180.49" x2="283.231" y2="179.206" gradientUnits="userSpaceOnUse">
      <stop stop-color="${colorSecondary}"/>
      <stop offset=".349" stop-color="${colorSecondary}" stop-opacity=".01"/>
      <stop offset=".656" stop-color="${colorSecondary}" stop-opacity="0"/>
      <stop offset="1" stop-color="${colorSecondary}"/>
    </linearGradient>
  </defs>
</svg>`;
