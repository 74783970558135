import Konva from 'konva';
import { OverlayElement } from '../../types';

import { Reference } from '../../utils/loaders';
import { CircleAreaGlyph } from '../glyphs/CircleAreaGlyph';
import { OverlayElementGlyphs, UpdatePositionOptions } from './interface';
import { findClosestRivalPlayerDistance, getPlayerReferences, isFrameInRange } from './utils';

type Options = {
  config: OverlayElement;
  overlayElementTypeId: string;
  references: Reference[];
  startFrame: number;
  endFrame: number;
};

export class CircleClosestRivalHighlight extends OverlayElementGlyphs {
  circle: CircleAreaGlyph;
  overlayElementTypeId: string;
  playerId: string;
  references: Reference[];
  startFrame: number;
  endFrame: number;
  priority: number;
  excludedPlayerIdsFromRender: string[] = [];
  isVisible: boolean = true;

  constructor({ config, endFrame, overlayElementTypeId, references, startFrame }: Options) {
    super();
    // TODO should we do this more explicit in config?
    const lineGlyphConfig = config.glyphs[0];

    this.circle = new CircleAreaGlyph({
      opacity: lineGlyphConfig.alpha ?? 1,
      size: lineGlyphConfig.size,
      colorPrimary: lineGlyphConfig.colorPrimary,
    });

    this.overlayElementTypeId = overlayElementTypeId;
    this.references = references;
    this.playerId = getPlayerReferences(this.references)[0];
    this.startFrame = startFrame;
    this.endFrame = endFrame;
    this.priority = config.priority;
  }

  addToLayer(layer: Konva.Layer) {
    layer.add(this.circle.shape);
  }

  update({ playersPositions, scale, frame, teams }: UpdatePositionOptions) {
    if (!isFrameInRange(frame, this)) return;

    const closestDistance = findClosestRivalPlayerDistance(this.playerId, playersPositions[frame], teams);
    const playersCoordinates = playersPositions?.[frame]?.[this.playerId];

    if (playersCoordinates && closestDistance) {
      this.excludedPlayerIdsFromRender.includes(this.playerId) ? this.hide() : this.show();
      this.circle.update(closestDistance, playersCoordinates, scale);
    }
  }

  removeFromLayer = () => {
    this.circle.shape.destroy();
  };

  show = () => {
    this.circle.shape.show();
    this.isVisible = true;
  };

  hide = () => {
    this.circle.shape.hide();
    this.isVisible = false;
  };

  setExcludedPlayerIdsFromRender(references: string[]) {
    this.excludedPlayerIdsFromRender = references;
  }
}
