import Konva from 'konva';
import { Glyph, OverlayElement } from '../../types';

import { Reference } from '../../utils/loaders';
import { ScatterGlyph } from '../glyphs/ScatterGlyph';
import { OverlayElementGlyphs, UpdatePositionOptions } from './interface';
import { findPlayersCoordinates, getPlayerReferences, isFrameInRange, sampleArrayValues } from './utils';

type Options = {
  config: OverlayElement;
  overlayElementTypeId: string;
  references: Reference[];
  startFrame: number;
  endFrame: number;
};

export class ScatterLine extends OverlayElementGlyphs {
  scatter: ScatterGlyph;
  overlayElementTypeId: string;
  playerId: string;
  references: Reference[];
  startFrame: number;
  endFrame: number;
  updated: boolean = false;
  priority: number;
  excludedPlayerIdsFromRender: string[] = [];
  isVisible: boolean = true;
  config: Glyph;

  constructor({ config, endFrame, overlayElementTypeId, references, startFrame }: Options) {
    super();
    // TODO should we do this more explicit in config?
    const lineGlyphConfig = config.glyphs[0];

    this.scatter = new ScatterGlyph({
      colorPrimary: lineGlyphConfig.colorPrimary,
      opacity: lineGlyphConfig.alpha ?? 1,
      size: lineGlyphConfig.size,
    });
    this.overlayElementTypeId = overlayElementTypeId;
    this.references = references;
    this.playerId = getPlayerReferences(this.references)[0];
    this.startFrame = startFrame;
    this.endFrame = endFrame;
    this.priority = config.priority;
    this.config = lineGlyphConfig;
  }

  addToLayer(layer: Konva.Layer) {
    layer.add(this.scatter.shape);
  }

  update({ playersPositions, scale, frame, pitchSize }: UpdatePositionOptions) {
    if (this.excludedPlayerIdsFromRender.includes(this.playerId) || !playersPositions[frame]) {
      this.hide();
      return;
    } else {
      this.show();
    }

    if (!isFrameInRange(frame, this) || this.updated) return;

    this.updated = true;

    const sampleOfFrames = sampleArrayValues(this.startFrame, this.endFrame, 8);
    const coordinatesOfPlayerInAllFrames = sampleOfFrames.map((frame) => {
      return findPlayersCoordinates([this.playerId], playersPositions[frame]);
    });

    this.scatter.update(
      coordinatesOfPlayerInAllFrames.map((coordinatesInFrame) => coordinatesInFrame[this.playerId]),
      scale,
    );
  }

  removeFromLayer = () => {
    this.scatter.shape.destroy();
  };

  show = () => {
    this.scatter.shape.show();
    this.isVisible = true;
  };

  hide = () => {
    this.updated = false;
    this.scatter.shape.destroyChildren();
    this.isVisible = false;
  };

  setExcludedPlayerIdsFromRender(references: string[]) {
    this.excludedPlayerIdsFromRender = references;
  }
}
