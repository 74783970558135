import Konva from 'konva';

import { Coordinates } from '../../types';

type Options = { colorPrimary: string; opacity: number; size: number };

const line = new Konva.Line({
  closed: true,
  lineCap: 'round',
  lineJoin: 'round',
  listening: false,
  points: [],
});

export class PolylineGlyph {
  shape: Konva.Line;
  size: number;

  constructor({ colorPrimary, opacity, size }: Options) {
    this.shape = line.clone({
      opacity: opacity,
      stroke: colorPrimary,
      strokeWidth: size,
    });
    this.size = size;
  }

  update(coordinates: Coordinates[], scaleFactor: number) {
    this.shape.strokeWidth(this.size * scaleFactor);
    this.shape.points(
      coordinates.reduce(
        (acc, coordinate) => [...acc, coordinate.x * scaleFactor, coordinate.y * scaleFactor],
        [] as number[],
      ),
    );
  }
}
