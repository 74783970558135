import { PlayersPosition } from '../../main';
import { Coordinates, PitchSize } from '../../types';
import { Reference, Segment } from '../../utils/loaders';
import { Teams } from '../types';
import { OverlayElementGlyphs } from './interface';

export const findPlayersCoordinates = (playerIds: string[], playersPositions: PlayersPosition) => {
  const resultPlayerIds = {} as { [key in string]: Coordinates };

  if (!playersPositions) return resultPlayerIds;

  for (let i = 0; i < playerIds.length; i++) {
    const playerId = playerIds[i];

    const playerCoordinates = playersPositions[playerId];
    if (!playerCoordinates) continue;
    resultPlayerIds[playerId] = playerCoordinates;
  }

  return resultPlayerIds;
};

export const findClosestRivalPlayerDistance = (
  playerId: string,
  playersPosition: PlayersPosition,
  teams: Teams,
): number | null => {
  const isPlayerInHomeTeam = teams.homeTeam.playerIds.some((homePlayerId) => homePlayerId === playerId);
  const rivalPlayerIds = isPlayerInHomeTeam ? teams.awayTeam.playerIds : teams.homeTeam.playerIds;

  let closestDistance = null;

  for (let i = 0; i < rivalPlayerIds.length; i++) {
    const rivalPosition = playersPosition[rivalPlayerIds[i]];
    const playerPosition = playersPosition[playerId];

    if (!rivalPosition || !playerPosition) continue;
    const y = rivalPosition.x - playerPosition.x;
    const x = rivalPosition.y - playerPosition.y;

    const distance = Math.sqrt(x * x + y * y);

    if (distance && (!closestDistance || distance < closestDistance)) {
      closestDistance = distance;
    }
  }

  return closestDistance;
};

export const getPlayerReferences = (references: Reference[]) => {
  return references.reduce(
    (acc, reference) => {
      if (reference.referenceType === 'players') {
        return reference.values.map((v) => v);
      }
      return acc;
    },
    <string[]>[],
  );
};

export const getStaticCoordinates = (references: Reference[]) => {
  return references.reduce(
    (acc, reference) => {
      if (reference.referenceType === 'static-coordinates') {
        return reference.values.map((value) => {
          return { x: Number(value[0]), y: Number(value[1]) };
        });
      }
      return acc;
    },
    <Coordinates[]>[],
  );
};

export const adjustStaticCoordinatesByPitchSize = (coordinates: Coordinates[], pitchSize: PitchSize) => {
  return coordinates.map((coordinate) => {
    return {
      x: pitchSize.length / 2 + coordinate.x,
      y: pitchSize.width / 2 + coordinate.y,
    };
  });
};

export const isFrameInRange = (frame: number, overlayElement: OverlayElementGlyphs) => {
  const isInRange = frame >= overlayElement.startFrame && frame <= overlayElement.endFrame;

  !isInRange ? overlayElement.hide() : overlayElement.show();

  return isInRange;
};

function generateFramesArray(start: number, end: number): number[] {
  const result = [];
  for (let i = start; i <= end; i++) {
    result.push(i);
  }
  return result;
}

export function sampleArrayValues(startFrame: number, endFrame: number, sampleSize: number): any[] {
  const listOfFrames = generateFramesArray(startFrame, endFrame);
  const result = [listOfFrames[0]];
  for (let i = 1; i < listOfFrames.length - 1; i += sampleSize) {
    result.push(listOfFrames[i]);
  }
  result.push(listOfFrames[listOfFrames.length - 1]);
  return result;
}

export function getPlayerTeam(playerId: string, teams: Teams) {
  return teams.homeTeam.playerIds.includes(playerId) ? teams.homeTeam.id : teams.awayTeam.id;
}

export function getCurrentSegment(frame: number, segments: Segment[]) {
  return segments.find((segment) => frame >= segment.startFrame && frame <= segment.endFrame);
}

export function defensiveLinePitchSide(
  playersXPositions: number[],
  pitchSize: PitchSize,
  teamId: string,
  segment?: Segment,
): 'left' | 'right' {
  if (segment) {
    if (segment.pitchLeftSideTeamId === teamId) return 'left';
    if (segment.pitchRightSideTeamId === teamId) return 'right';
  }

  const isDefensiveLineInLeftHalf =
    playersXPositions.reduce((acc, xpos) => acc + xpos, 0) / playersXPositions.length < pitchSize.length / 2;

  return isDefensiveLineInLeftHalf ? 'left' : 'right';
}
