import Konva from 'konva';

import { Coordinates } from '../../types';

type Options = { colorPrimary: string; opacity: number; size: number };

const line = new Konva.Line({ closed: true, listening: false });

export class PolygonGlyph {
  shape: Konva.Line;
  size: number;

  constructor({ colorPrimary, opacity, size }: Options) {
    this.shape = line.clone({
      fill: colorPrimary,
      opacity: opacity,
      points: [],
    });
    this.size = size;
  }

  update(coordinates: Coordinates[], scaleFactor: number) {
    this.shape.strokeWidth(this.size * scaleFactor);
    this.shape.points(
      coordinates.reduce(
        (acc, coordinate) => [...acc, coordinate.x * scaleFactor, coordinate.y * scaleFactor],
        [] as number[],
      ),
    );
  }
}
