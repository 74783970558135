import { Colors } from 'kognia-ui';
import { circleSvg } from './Circle';
import { circleDashedSvg } from './CircleDashed';
import { semiCircleSvg } from './SemiCircle';
import { isNode } from '../../node-script/checkEnv';

const createInlineSvg = (svg: string) =>
  isNode ? `data:image/svg+xml;utf8,${svg}` : `data:image/svg+xml;utf8,${encodeURIComponent(svg)}`;

// TODO: improve imageInterface type or remove it if possible
const createSemicircle = (colorPrimary: Colors, colorSecondary: Colors, imageInterface: any) => {
  const image = new imageInterface();
  image.src = createInlineSvg(semiCircleSvg(colorPrimary, colorSecondary));

  return image;
};

const createCircle = (colorPrimary: Colors, colorSecondary: Colors, imageInterface: any) => {
  const image = new imageInterface();
  image.src = createInlineSvg(circleSvg(colorPrimary, colorSecondary));

  return image;
};

const createCircleDashed = (colorPrimary: Colors, colorSecondary: Colors, imageInterface: any) => {
  const image = new imageInterface();
  image.src = createInlineSvg(circleDashedSvg(colorPrimary, colorSecondary));

  return image;
};

export { createSemicircle, createCircleDashed, createCircle };
