import Konva from 'konva';
import { GlyphElement, OverlayElement } from '../../types';

import { Reference } from '../../utils/loaders';
import { ArrowGlyph } from '../glyphs/ArrowGlyph';
import { OverlayElementGlyphs, UpdatePositionOptions } from './interface';
import { findPlayersCoordinates, getPlayerReferences, isFrameInRange, sampleArrayValues } from './utils';

type Options = {
  config: OverlayElement;
  overlayElementTypeId: string;
  references: Reference[];
  startFrame: number;
  endFrame: number;
};

export class PolyLineWithArrowCurrentPosition extends OverlayElementGlyphs {
  arrow: ArrowGlyph;
  overlayElementTypeId: string;
  playersIds: string[];
  references: Reference[];
  startFrame: number;
  endFrame: number;
  updated: boolean = false;
  priority: number;
  excludedPlayerIdsFromRender: string[] = [];
  isVisible: boolean = true;

  constructor({ config, endFrame, overlayElementTypeId, references, startFrame }: Options) {
    super();
    // TODO should we do this more explicit in config?
    const lineGlyphConfig = config.glyphs[0];

    this.arrow = new ArrowGlyph({
      colorPrimary: lineGlyphConfig.colorPrimary,
      dashed: lineGlyphConfig.element === GlyphElement.DASHED_LINE,
      opacity: lineGlyphConfig.alpha ?? 1,
      size: lineGlyphConfig.size,
    });
    this.overlayElementTypeId = overlayElementTypeId;
    this.references = references;
    this.playersIds = getPlayerReferences(this.references);
    this.startFrame = startFrame;
    this.endFrame = endFrame;
    this.priority = config.priority;
  }

  addToLayer(layer: Konva.Layer) {
    layer.add(this.arrow.shape);
  }

  update({ playersPositions, scale, frame }: UpdatePositionOptions) {
    this.excludedPlayerIdsFromRender.some((p) => this.playersIds.includes(p)) ? this.hide() : this.show();
    if (!isFrameInRange(frame, this)) return;

    const sampleOfFrames = sampleArrayValues(frame, this.endFrame, 12);
    const coordinatesOfPlayerInAllFrames = sampleOfFrames.map((frame) => {
      return {
        frame: frame,
        cords: findPlayersCoordinates([this.playersIds[0]], playersPositions[frame]),
      };
    });

    const coordinates = coordinatesOfPlayerInAllFrames
      .filter((item) => item.frame > frame)
      .map((coordinatesInFrame) => coordinatesInFrame.cords[this.playersIds[0]]);

    if (coordinates.some((c) => c === undefined)) {
      this.hide();
      return;
    } else {
      this.show();
    }

    this.arrow.update(coordinates, scale);
  }

  removeFromLayer = () => {
    this.arrow.shape.destroy();
  };

  show = () => {
    this.arrow.shape.show();
    this.isVisible = true;
  };

  hide = () => {
    this.arrow.shape.hide();
    this.isVisible = false;
  };

  setExcludedPlayerIdsFromRender(references: string[]) {
    this.excludedPlayerIdsFromRender = references;
  }
}
