import Konva from 'konva';
import { OverlayElement } from '../../types';

import { Reference } from '../../utils/loaders';
import { SemiCircleGlyph } from '../glyphs/SemiCircleGlyph';
import { OverlayElementGlyphs, UpdatePositionOptions } from './interface';
import { findPlayersCoordinates, getPlayerReferences, isFrameInRange } from './utils';

type Options = {
  config: OverlayElement;
  overlayElementTypeId: string;
  references: Reference[];
  startFrame: number;
  endFrame: number;
  imageInterface: unknown;
};

const getRotationFromStyle = (style: string) => {
  switch (style) {
    case 'semicircle-left':
      return 180;
    case 'semicircle-up':
      return -90;
    case 'semicircle-down':
      return 90;
    case 'semicircle-right':
    default:
      return 0;
  }
};

export class SemiCircleHighlight extends OverlayElementGlyphs {
  semiCircle: SemiCircleGlyph;
  overlayElementTypeId: string;
  playerId: string;
  references: Reference[];
  startFrame: number;
  endFrame: number;
  priority: number;
  excludedPlayerIdsFromRender: string[] = [];
  isVisible: boolean = true;

  constructor({ config, endFrame, overlayElementTypeId, references, startFrame, imageInterface }: Options) {
    super();
    // TODO should we do this more explicit in config?
    const lineGlyphConfig = config.glyphs[0];
    const rotation = getRotationFromStyle(lineGlyphConfig.style ?? '');

    this.semiCircle = new SemiCircleGlyph({
      opacity: lineGlyphConfig.alpha ?? 1,
      size: lineGlyphConfig.size,
      colorPrimary: lineGlyphConfig.colorPrimary,
      colorSecondary: lineGlyphConfig.colorSecondary ?? lineGlyphConfig.colorPrimary,
      rotation,
      imageInterface,
    });

    this.overlayElementTypeId = overlayElementTypeId;
    this.references = references;
    this.playerId = getPlayerReferences(this.references)[0];
    this.startFrame = startFrame;
    this.endFrame = endFrame;
    this.priority = config.priority;
  }

  addToLayer(layer: Konva.Layer) {
    layer.add(this.semiCircle.shape);
  }

  update({ playersPositions, scale, frame }: UpdatePositionOptions) {
    if (!isFrameInRange(frame, this)) return;
    const playersCoordinates = findPlayersCoordinates([this.playerId], playersPositions[frame])?.[this.playerId];

    this.excludedPlayerIdsFromRender.includes(this.playerId) || !playersCoordinates ? this.hide() : this.show();

    playersCoordinates && this.semiCircle.update(playersCoordinates, scale);
  }

  removeFromLayer = () => {
    this.semiCircle.shape.destroy();
  };

  show = () => {
    this.semiCircle.shape.show();
    this.isVisible = true;
  };

  hide = () => {
    this.semiCircle.shape.hide();
    this.isVisible = false;
  };

  setExcludedPlayerIdsFromRender(references: string[]) {
    this.excludedPlayerIdsFromRender = references;
  }
}
