import Konva from 'konva';

import { PlayersPositions } from '../../main';
import { PitchSize } from '../../types';
import { Reference, Segment } from '../../utils/loaders';
import { Teams } from '../types';

export interface UpdatePositionOptions {
  frame: number;
  pitchSize: PitchSize;
  playersPositions: PlayersPositions;
  segments: Segment[];
  scale: number;
  teams: Teams;
}

export abstract class OverlayElementGlyphs {
  abstract overlayElementTypeId: string;
  abstract startFrame: number;
  abstract endFrame: number;
  abstract references: Reference[];
  abstract priority: number;
  abstract excludedPlayerIdsFromRender: string[];
  abstract isVisible: boolean;

  abstract addToLayer(layer: Konva.Layer): void;

  abstract removeFromLayer(): void;

  abstract update(options: UpdatePositionOptions): void;

  abstract hide(): void;

  abstract show(): void;

  abstract setExcludedPlayerIdsFromRender(references: string[]): void;
}
