import { Colors } from 'kognia-ui';

export const circleDashedSvg = (colorPrimary: Colors, colorSecondary: Colors) => `
<svg xmlns="http://www.w3.org/2000/svg" width="132" height="132" fill="none">
  <g stroke-width="9">
    <circle cx="66" cy="66" r="61.5" stroke="url(#A)" stroke-dasharray="18 18"/>
    <circle cx="66" cy="66" r="38.5" stroke="url(#B)"/>
  </g>
  <defs>
  <linearGradient id="A" x1="0" y1="69.474" x2="132" y2="69.474" gradientUnits="userSpaceOnUse">
    <stop stop-color="${colorPrimary}"/>
    <stop offset=".438" stop-color="${colorPrimary}" stop-opacity="0"/>
    <stop offset=".604" stop-color="${colorPrimary}" stop-opacity="0"/>
    <stop offset="1" stop-color="${colorPrimary}"/>
  </linearGradient>
  <linearGradient id="B" x1="23" y1="69.46" x2="109" y2="68.966" gradientUnits="userSpaceOnUse">
    <stop stop-color="${colorSecondary}"/>
    <stop offset=".349" stop-color="${colorSecondary}" stop-opacity="0"/>
    <stop offset=".656" stop-color="${colorSecondary}" stop-opacity="0"/>
    <stop offset="1" stop-color="${colorSecondary}"/>
  </linearGradient>
  </defs>
</svg>`;
