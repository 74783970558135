import Konva from 'konva';
import { Coordinates, OverlayElement } from '../../types';

import { Reference } from '../../utils/loaders';
import { PolygonGlyph } from '../glyphs/PolygonGlyph';
import { OverlayElementGlyphs, UpdatePositionOptions } from './interface';
import {
  adjustStaticCoordinatesByPitchSize,
  findPlayersCoordinates,
  getPlayerReferences,
  getStaticCoordinates,
  isFrameInRange,
} from './utils';

type Options = {
  config: OverlayElement;
  overlayElementTypeId: string;
  references: Reference[];
  startFrame: number;
  endFrame: number;
};

export class PolygonArea extends OverlayElementGlyphs {
  area: PolygonGlyph;
  overlayElementTypeId: string;
  playersIds: string[];
  staticCoordinates: Coordinates[];
  references: Reference[];
  startFrame: number;
  endFrame: number;
  priority: number;
  excludedPlayerIdsFromRender: string[] = [];
  isVisible: boolean = true;

  constructor({ config, endFrame, overlayElementTypeId, references, startFrame }: Options) {
    super();
    // TODO should we do this more explicit in config?
    const polygonGlyphConfig = config.glyphs[0];

    this.area = new PolygonGlyph({
      colorPrimary: polygonGlyphConfig.colorPrimary,
      opacity: polygonGlyphConfig.alpha ?? 1,
      size: polygonGlyphConfig.size,
    });
    this.overlayElementTypeId = overlayElementTypeId;
    this.references = references;
    this.playersIds = getPlayerReferences(this.references);

    this.staticCoordinates = getStaticCoordinates(this.references);
    this.startFrame = startFrame;
    this.endFrame = endFrame;
    this.priority = config.priority;
  }

  addToLayer(layer: Konva.Layer) {
    layer.add(this.area.shape);
  }

  update({ playersPositions, scale, frame, pitchSize }: UpdatePositionOptions) {
    if (!isFrameInRange(frame, this)) return;

    const playersCoordinates = findPlayersCoordinates(this.playersIds, playersPositions[frame]);

    this.area.update(
      [
        ...this.playersIds.map((playerId) => playersCoordinates[playerId]),
        ...adjustStaticCoordinatesByPitchSize(this.staticCoordinates, pitchSize),
      ],
      scale,
    );

    this.area.shape.show();
  }

  removeFromLayer = () => {
    this.area.shape.destroy();
  };

  show = () => {
    this.area.shape.show();
    this.isVisible = true;
  };

  hide = () => {
    this.area.shape.hide();
    this.isVisible = false;
  };

  setExcludedPlayerIdsFromRender(references: string[]) {
    this.excludedPlayerIdsFromRender = references;
  }
}
