import Konva from 'konva';
import { OverlayElement } from '../../types';

import { Reference } from '../../utils/loaders';
import { HighlightGlyph } from '../glyphs/HighlightGlyph';
import { LineGlyph } from '../glyphs/LineGlyph';
import { OverlayElementGlyphs, UpdatePositionOptions } from './interface';
import { findPlayersCoordinates, getPlayerReferences, isFrameInRange } from './utils';

type Options = {
  config: OverlayElement;
  overlayElementTypeId: string;
  references: Reference[];
  startFrame: number;
  endFrame: number;
  imageInterface: unknown;
};

export class WidthPlayerHighlight extends OverlayElementGlyphs {
  highlightPlayer: HighlightGlyph;
  line: LineGlyph;
  overlayElementTypeId: string;
  playersIds: string[];
  references: Reference[];
  startFrame: number;
  endFrame: number;
  priority: number;
  excludedPlayerIdsFromRender: string[] = [];
  isVisible: boolean = true;

  constructor({ config, endFrame, overlayElementTypeId, references, startFrame, imageInterface }: Options) {
    super();
    // TODO should we do this more explicit in config?
    const highlightGlyphConfig = config.glyphs[0];
    const lineGlyphConfig = config.glyphs[1];
    const playersIds = getPlayerReferences(references);

    this.highlightPlayer = new HighlightGlyph({
      colorPrimary: highlightGlyphConfig.colorPrimary,
      colorSecondary: highlightGlyphConfig.colorSecondary,
      opacity: highlightGlyphConfig.alpha ?? 1,
      playerId: playersIds[0],
      size: highlightGlyphConfig.size,
      startX: 0,
      startY: 0,
      type: highlightGlyphConfig.style === 'circle' ? 'circle' : 'dashed',
      imageInterface,
    });

    this.line = new LineGlyph({
      colorPrimary: lineGlyphConfig.colorPrimary,
      opacity: lineGlyphConfig.alpha ?? 1,
      size: lineGlyphConfig.size,
    });
    this.endFrame = endFrame;
    this.overlayElementTypeId = overlayElementTypeId;
    this.references = references;
    this.playersIds = getPlayerReferences(this.references);
    this.startFrame = startFrame;
    this.priority = config.priority;
  }

  addToLayer(layer: Konva.Layer) {
    layer.add(this.highlightPlayer.shape);
    layer.add(this.line.shape);
  }

  update({ playersPositions, scale, frame, pitchSize }: UpdatePositionOptions) {
    if (!isFrameInRange(frame, this)) return;

    const playersCoordinates = findPlayersCoordinates([this.highlightPlayer.playerId], playersPositions[frame]);
    const playerCoordinates = playersCoordinates[this.highlightPlayer.playerId];

    this.excludedPlayerIdsFromRender.includes(this.highlightPlayer.playerId) || !playerCoordinates
      ? this.hide()
      : this.show();

    if (!playerCoordinates) {
      console.warn(
        `No coordinates for player ${this.highlightPlayer.playerId} in frame ${frame} in overlay element WidthPlayerHighlight`,
      );
      return;
    }

    this.line.update(
      [
        playerCoordinates,
        {
          x: playerCoordinates.x,
          y: playerCoordinates.y > pitchSize.width / 2 ? pitchSize.width : 0,
        },
      ],
      scale,
    );
    this.highlightPlayer.update(
      playersCoordinates[this.highlightPlayer.playerId].x,
      playersCoordinates[this.highlightPlayer.playerId].y,
      scale,
    );
  }

  removeFromLayer = () => {
    this.highlightPlayer.shape.destroy();
    this.line.shape.destroy();
  };

  show = () => {
    this.highlightPlayer.shape.show();
    this.line.shape.show();
    this.isVisible = true;
  };

  hide = () => {
    this.highlightPlayer.shape.hide();
    this.line.shape.hide();
    this.isVisible = false;
  };

  setExcludedPlayerIdsFromRender(references: string[]) {
    this.excludedPlayerIdsFromRender = references;
  }
}
