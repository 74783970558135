import Konva from 'konva';

import { Coordinates } from '../../types';

type Options = { colorPrimary: string; opacity: number; size: number; dashed?: boolean; id?: string };

const arrow = new Konva.Arrow({
  points: [],
  lineCap: 'round',
  lineJoin: 'round',
  listening: false,
});

const DASH_SPACING = 8;
export class ArrowGlyph {
  shape: Konva.Arrow;
  size: number;
  isDashed: boolean;
  pointerLength: number = 0.8;
  pointerWidth: number = 0.6;

  constructor({ colorPrimary, opacity, size, dashed = false, id }: Options) {
    this.isDashed = Boolean(dashed);
    this.size = size;
    const dashedOptions = dashed ? { dash: this.getDashedLinePoints(1) } : {};

    this.shape = arrow.clone({
      fill: colorPrimary,
      opacity: opacity,
      pointerLength: this.pointerLength,
      pointerWidth: this.pointerWidth,
      stroke: colorPrimary,
      strokeWidth: size,
      id,
      ...dashedOptions,
    });
  }

  update(coordinates: Coordinates[], scaleFactor: number) {
    this.shape.pointerLength(this.pointerLength * scaleFactor);
    this.shape.pointerWidth(this.pointerWidth * scaleFactor);
    this.shape.dash(this.getDashedLinePoints(scaleFactor));
    this.shape.strokeWidth(this.size * scaleFactor);
    this.shape.points(
      coordinates.reduce(
        (acc: number[], coordinate) => [...acc, coordinate.x * scaleFactor, coordinate.y * scaleFactor],
        [],
      ),
    );
  }

  private getDashedLinePoints(scaleFactor: number) {
    return this.isDashed ? [this.size * scaleFactor * DASH_SPACING, this.size * scaleFactor * DASH_SPACING] : [];
  }
}
