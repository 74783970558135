import Konva from 'konva';
import { GlyphElement, OverlayElement } from '../../types';

import { Reference } from '../../utils/loaders';
import { ArrowGlyph } from '../glyphs/ArrowGlyph';
import { OverlayElementGlyphs, UpdatePositionOptions } from './interface';
import { getPlayerReferences, isFrameInRange } from './utils';

type Options = {
  config: OverlayElement;
  overlayElementTypeId: string;
  references: Reference[];
  startFrame: number;
  endFrame: number;
};

export class LineToHalfwayWithArrows extends OverlayElementGlyphs {
  arrow: ArrowGlyph;
  overlayElementTypeId: string;
  playerId: string;
  references: Reference[];
  startFrame: number;
  endFrame: number;
  priority: number;
  excludedPlayerIdsFromRender: string[] = [];
  isVisible: boolean = true;

  constructor({ config, endFrame, overlayElementTypeId, references, startFrame }: Options) {
    super();
    // TODO should we do this more explicit in config?
    const lineGlyphConfig = config.glyphs[0];

    this.arrow = new ArrowGlyph({
      opacity: lineGlyphConfig.alpha ?? 1,
      size: lineGlyphConfig.size,
      colorPrimary: lineGlyphConfig.colorPrimary,
      dashed: lineGlyphConfig.element === GlyphElement.DASHED_LINE,
    });

    this.endFrame = endFrame;
    this.overlayElementTypeId = overlayElementTypeId;
    this.references = references;
    this.playerId = getPlayerReferences(this.references)[0];
    this.startFrame = startFrame;
    this.priority = config.priority;
  }

  addToLayer(layer: Konva.Layer) {
    layer.add(this.arrow.shape);
  }

  update({ playersPositions, scale, frame, pitchSize }: UpdatePositionOptions) {
    if (!isFrameInRange(frame, this)) return;

    this.excludedPlayerIdsFromRender.includes(this.playerId) ? this.hide() : this.show();
    const player = playersPositions?.[frame]?.[this.playerId];

    if (player) {
      this.arrow.update(
        [
          player,
          {
            x: pitchSize.length / 2,
            y: player.y,
          },
        ],
        scale,
      );
    }
  }

  removeFromLayer = () => {
    this.arrow.shape.destroy();
  };

  show = () => {
    this.arrow.shape.show();
    this.isVisible = true;
  };

  hide = () => {
    this.arrow.shape.hide();
    this.isVisible = false;
  };

  setExcludedPlayerIdsFromRender(references: string[]) {
    this.excludedPlayerIdsFromRender = references;
  }
}
