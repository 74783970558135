import Konva from 'konva';

import { Coordinates } from '../../types';

type Options = { colorPrimary: string; opacity: number; size: number; dashed?: boolean };

const arrow = new Konva.Arrow({
  points: [],
  listening: false,
});

export class ArrowHighlightGlyph {
  shape: Konva.Arrow;
  size: number;

  constructor({ colorPrimary, opacity, size }: Options) {
    this.size = size;

    this.shape = arrow.clone({
      fill: colorPrimary,
      opacity: opacity,
      stroke: colorPrimary,
      strokeWidth: size,
    });
  }

  update(coordinates: Coordinates[], scaleFactor: number) {
    this.shape.pointerLength((this.size / 3) * scaleFactor);
    this.shape.pointerWidth((this.size / 3) * scaleFactor);
    this.shape.strokeWidth((this.size / 1.5) * scaleFactor);

    this.shape.points(coordinates.map((coordinate) => [coordinate.x * scaleFactor, coordinate.y * scaleFactor]).flat());
  }
}
