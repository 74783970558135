import Konva from 'konva';
import { OverlayElement } from '../../types';

import { Reference } from '../../utils/loaders';
import { PolylineGlyph } from '../glyphs/PolylineGlyph';
import { OverlayElementGlyphs, UpdatePositionOptions } from './interface';
import { findPlayersCoordinates, getPlayerReferences, isFrameInRange } from './utils';

type Options = {
  config: OverlayElement;
  overlayElementTypeId: string;
  references: Reference[];
  startFrame: number;
  endFrame: number;
};

export class TeamPerimeter extends OverlayElementGlyphs {
  area: PolylineGlyph;
  overlayElementTypeId: string;
  playersIds: string[];
  references: Reference[];
  startFrame: number;
  endFrame: number;
  priority: number;
  excludedPlayerIdsFromRender: string[] = [];
  isVisible: boolean = true;

  constructor({ config, endFrame, overlayElementTypeId, references, startFrame }: Options) {
    super();
    // TODO should we do this more explicit in config?
    const polygonGlyphConfig = config.glyphs[0];

    this.area = new PolylineGlyph({
      colorPrimary: polygonGlyphConfig.colorPrimary,
      opacity: polygonGlyphConfig.alpha ?? 1,
      size: polygonGlyphConfig.size,
    });
    this.overlayElementTypeId = overlayElementTypeId;
    this.references = references;
    this.playersIds = getPlayerReferences(this.references);
    this.startFrame = startFrame;
    this.endFrame = endFrame;
    this.priority = config.priority;
  }

  addToLayer(layer: Konva.Layer) {
    layer.add(this.area.shape);
  }

  update({ playersPositions, scale, frame }: UpdatePositionOptions) {
    if (!isFrameInRange(frame, this)) return;

    const playersCoordinates = findPlayersCoordinates(this.playersIds, playersPositions[frame]);

    this.excludedPlayerIdsFromRender.some((p) => this.playersIds.includes(p)) ? this.hide() : this.show();

    this.area.update(
      this.playersIds
        .filter((playerId) => {
          if (!playersCoordinates[playerId]) {
            console.warn(`No coordinates for player ${playerId} in frame ${frame} in overlay element TeamPerimeter`);
          }
          return playersCoordinates[playerId];
        })
        .map((playerId) => playersCoordinates[playerId]),
      scale,
    );

    this.area.shape.show();
  }

  removeFromLayer = () => {
    this.area.shape.destroy();
  };

  show = () => {
    this.area.shape.show();
    this.isVisible = true;
  };

  hide = () => {
    this.area.shape.hide();
    this.isVisible = false;
  };

  setExcludedPlayerIdsFromRender(references: string[]) {
    this.excludedPlayerIdsFromRender = references;
  }
}
