import { Colors } from 'kognia-ui';

export const Events = {
  OVERLAY_ELEMENTS_META_DATA: 'OVERLAY_ELEMENTS_META_DATA',
  SET_RECORDING_DATA: 'SET_RECORDING_DATA',
  CHANGE_QUALITY: 'CHANGE_QUALITY',
  CHANGE_RENDER_SCALE: 'CHANGE_RENDER_SCALE',
  DRAW_FRAME_IN_CANVAS: 'DRAW_FRAME_IN_CANVAS',
  END_LOADING_DATA: 'END_LOADING_DATA',
  END_LOADING_ASYNC_DATA: 'END_LOADING_ASYNC_DATA',
  INIT: 'INIT',
  LOAD: 'LOAD',
  LOAD_METADATA: 'LOAD_METADATA',
  START_LOADING_DATA: 'START_LOADING_DATA',
  START_LOADING_ASYNC_DATA: 'START_LOADING_ASYNC_DATA',
  UPDATE_METADATA: 'UPDATE_METADATA',
} as const;

export enum GlyphElement {
  ARROW = 'arrow',
  ARROW_HIGHLIGHT = 'arrow_highlight',
  CIRCLE_AREA = 'circle_area',
  DASHED_LINE = 'dashed_line',
  HIGHLIGHT = 'highlight',
  LINE = 'line',
  POLYARROW = 'polyarrow',
  POLYGON = 'polygon',
  POLYLINE = 'polyline',
  SCATTER = 'scatter',
}

export type Glyph = {
  element: GlyphElement;
  size: number;
  colorPrimary: Colors;
  colorSecondary?: Colors;
  alpha?: number;
  style?: string;
  dynamic?: 'lin';
  initial_size?: number;
  final_size?: number;
};

export type OverlayElement = {
  priority: number;
  glyphs: Glyph[];
};

export enum OverlayElementNames {
  DEFENSIVE_LINE_PLAYER_DEPTH_HIGHLIGHT = 'defensive_line_player_depth_highlight',
  DEFENSIVE_LINE_WIDTH_GAP_OK = 'defensive_line_width_gap_ok',
  DEFENSIVE_LINE_WIDTH_GAP_NOT_OK = 'defensive_line_width_gap_not_ok',
  SWITCH_OF_PLAY_PASSER_HIGHLIGHT = 'switch_of_play_passer_highlight',
  SWITCH_OF_PLAY_RECEIVER_HIGHLIGHT = 'switch_of_play_receiver_highlight',
  SWITCH_OF_PLAY_PASS_ARROW = 'switch_of_play_pass_arrow',
  MARKED_ATTACKING_PLAYER_HIGHLIGHT = 'marked_attacking_player_highlight',
  MARKER_DEFENDING_PLAYER_HIGHLIGHT = 'marker_defending_player_highlight',
  MARKER_TO_MARKED_LINE = 'marker_to_marked_line',
  UNMARKED_ATTACKING_PLAYER_HIGHLIGHT = 'unmarked_attacking_player_highlight',
  DEFENDING_PLAYER_MARKING_POSSESSOR_HIGHLIGHT = 'defending_player_marking_possessor_highlight',
  FINISHING_PASS_OVERCOMING_PASS_ARROW = 'finishing_pass_overcoming_pass_arrow',
  FINISHING_PASS_PASSER_HIGHLIGHT = 'finishing_pass_passer_highlight',
  REALIZED_FINISHING_SUPPORT_HIGHLIGHT = 'realized_finishing_support_highlight',
  REALIZED_FINISHING_SUPPORT_SUPPORTING_PLAYER_PATH = 'realized_finishing_support_supporting_player_path',
  OVERCOME_PLAYER_HIGHLIGHT = 'overcome_player_highlight',
  OVERCOMING_PASS_PASSER_HIGHLIGHT = 'overcoming_pass_passer_highlight',
  OVERCOMING_PASS_ARROW = 'overcoming_pass_arrow',
  VERTICALLY_OVERCOMING_PASS_RECEIVER_HIGHLIGHT_SEMICIRCLE_LEFT = 'vertically_overcoming_pass_receiver_highlight_semicircle_left',
  VERTICALLY_OVERCOMING_PASS_RECEIVER_HIGHLIGHT_SEMICIRCLE_RIGHT = 'vertically_overcoming_pass_receiver_highlight_semicircle_right',
  REALIZED_VERTICAL_SUPPORT_SUPPORTING_PLAYER_PATH = 'realized_vertical_support_supporting_player_path',
  HORIZONTALLY_OVERCOMING_PASS_RECEIVER_HIGHLIGHT_SEMICIRCLE_DOWN = 'horizontally_overcoming_pass_receiver_highlight_semicircle_down',
  HORIZONTALLY_OVERCOMING_PASS_RECEIVER_HIGHLIGHT_SEMICIRCLE_UP = 'horizontally_overcoming_pass_receiver_highlight_semicircle_up',
  REALIZED_HORIZONTAL_SUPPORT_SUPPORTING_PLAYER_PATH = 'realized_horizontal_support_supporting_player_path',
  EMERGENCY_PASS_PASSER_HIGHLIGHT = 'emergency_pass_passer_highlight',
  EMERGENCY_PASS_RECEIVER_HIGHLIGHT = 'emergency_pass_receiver_highlight',
  STRIKER_EMERGENCY_PASS_SUPPORTER_HIGHLIGHT_SEMICIRCLE_UP = 'striker_emergency_pass_supporter_highlight_semicircle_up',
  EMERGENCY_SUPPORT_SUPPORTING_PLAYER_PATH = 'emergency_support_supporting_player_path',
  RECEIVING_BETWEEN_LINES_CLOSEST_PLAYER_CIRCLE = 'receiving_between_lines_closest_player_circle',
  PASSING_BETWEEN_LINES_PASSER_HIGHLIGHT = 'passing_between_lines_passer_highlight',
  WIDE_PLAYER_HIGHLIGHT = 'wide_player_highlight',
  TEAM_PERIMETER = 'team_perimeter',
  TEAM_PERIMETER_AFTER_RECOVERY = 'team_perimeter_after_recovery',
  TEAM_PERIMETER_AFTER_LOSS = 'team_perimeter_after_loss',
  DEFENSIVE_LINE_TO_HALFWAY_LINE_ARROWS = 'defensive_line_to_halfway_line_arrows',
  RUN_BEHIND_DEFENSIVE_LINE = 'run_behind_defensive_line',
  PLAYER_TAKING_ADVANTAGE_OF_DEFENSIVE_LINE_IMBALANCE_HIGHLIGHT = 'player_taking_advantage_of_defensive_line_imbalance_highlight',
  NEUTRALIZING_OPPONENT_ADVANTAGE_OF_DEFENSIVE_LINE_IMBALANCE_ATTACKING_PLAYER_HIGHLIGHT = 'neutralizing_opponent_advantage_of_defensive_line_imbalance_attacking_player_highlight',
  FINISHING_PLAYER_HIGHLIGHT = 'finishing_player_highlight',
  LONG_BALL_PASS_ARROW = 'long_ball_pass_arrow',
  LONG_BALL_PASSER_HIGHLIGHT = 'long_ball_passer_highlight',
  FINISHING_AFTER_CROSS_PLAYER_HIGHLIGHT = 'finishing_after_cross_player_highlight',
  GOAL_KICK_START_PASSER_HIGHLIGHT = 'goal_kick_start_passer_highlight',
  GOAL_KICK_START_RECEIVER_HIGHLIGHT = 'goal_kick_start_receiver_highlight',
  GOAL_KICK_SECTION_HIGHLIGHT = 'goal_kick_section_highlight',
  CROSS_PASSER_HIGHLIGHT = 'cross_passer_highlight',
  CROSS_SAME_TEAM_TOUCH_ZONE_BORDER_HIGHLIGHT = 'cross_same_team_touch_zone_border_highlight',
  CROSS_OPPOSITION_TEAM_TOUCH_ZONE_BORDER_HIGHLIGHT = 'cross_opposition_team_touch_zone_border_highlight',
  FOUL_PLAYER_HIGHLIGHT = 'foul_player_highlight',
  FOUL_CIRCLE_HIGHLIGHT = 'foul_circle_highlight',
  PROGRESSION_PLAYER_HIGHLIGHT = 'progression_player_highlight',
  PROGRESSION_AFTER_RECOVERY_ARROW = 'progression_after_recovery_arrow',
  POSSESSION_PLAYER_HIGHLIGHT = 'possession_player_highlight',
  POSSESSION_CIRCLE_HIGHLIGHT = 'possession_circle_highlight',
  PRESSING_AFTER_LOSS_HIGHLIGHT = 'pressing_after_loss_highlight',
  HOLDING_AFTER_LOSS_HIGHLIGHT = 'holding_after_loss_highlight',
  DEFENDER_PRESSING_POSSESSOR_HIGHLIGHT = 'defender_pressing_possessor_highlight',
  MOVING_FORWARD_DURING_ORGANIZED_PRESSURE_ARROWS = 'moving_forward_during_organized_pressure_arrows',
  DEFENDING_RUN_BEHIND_DEFENSIVE_LINE = 'defending_run_behind_defensive_line',
  CLEAR_THE_BOX_DEFENDER_ARROW = 'clear_the_box_defender_arrow',
  SECOND_BALL_OFFENSIVE_CONTROLLER_HIGHLIGHT = 'second_ball_offensive_controller_highlight',
  SECOND_BALL_DEFENSIVE_CONTROLLER_HIGHLIGHT = 'second_ball_defensive_controller_highlight',
  RUN_INTO_THE_BOX = 'run_into_the_box',
  DEFENDING_RUN_INTO_THE_BOX = 'defending_run_into_the_box',
  PASS_BEHIND_DEFENSIVE_LINE_RECEIVER_HIGHLIGHT = 'pass_behind_defensive_line_receiver_highlight',
  PASS_BEHIND_DEFENSIVE_LINE_ARROW = 'pass_behind_defensive_line_arrow',
}

type OverlayElements = {
  [key in OverlayElementNames]: OverlayElement;
};

export const overlayElementTypes: OverlayElements = {
  [OverlayElementNames.DEFENSIVE_LINE_PLAYER_DEPTH_HIGHLIGHT]: {
    priority: -1,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.white,
        colorSecondary: Colors.red,
        style: 'circle',
      },
      {
        element: GlyphElement.POLYGON,
        size: 1,
        colorPrimary: Colors.orange,
        alpha: 0.5,
      },
    ],
  },
  [OverlayElementNames.DEFENSIVE_LINE_WIDTH_GAP_OK]: {
    priority: -1,
    glyphs: [
      {
        element: GlyphElement.LINE,
        size: 0.1,
        colorPrimary: Colors.red,
      },
    ],
  },
  [OverlayElementNames.DEFENSIVE_LINE_WIDTH_GAP_NOT_OK]: {
    priority: -1,
    glyphs: [
      {
        element: GlyphElement.DASHED_LINE,
        size: 0.1,
        colorPrimary: Colors.red,
      },
    ],
  },
  [OverlayElementNames.SWITCH_OF_PLAY_PASSER_HIGHLIGHT]: {
    priority: 2,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.white,
        colorSecondary: Colors.aqua,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.SWITCH_OF_PLAY_RECEIVER_HIGHLIGHT]: {
    priority: 2,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.white,
        colorSecondary: Colors.white,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.SWITCH_OF_PLAY_PASS_ARROW]: {
    priority: 2,
    glyphs: [
      {
        element: GlyphElement.ARROW,
        size: 0.1,
        colorPrimary: Colors.aqua,
      },
    ],
  },
  [OverlayElementNames.MARKED_ATTACKING_PLAYER_HIGHLIGHT]: {
    priority: -1,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.white,
        colorSecondary: Colors.red,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.MARKER_DEFENDING_PLAYER_HIGHLIGHT]: {
    priority: -1,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.white,
        colorSecondary: Colors.red,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.MARKER_TO_MARKED_LINE]: {
    priority: -1,
    glyphs: [
      {
        element: GlyphElement.LINE,
        size: 0.1,
        colorPrimary: Colors.red,
      },
    ],
  },
  [OverlayElementNames.UNMARKED_ATTACKING_PLAYER_HIGHLIGHT]: {
    priority: -1,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.white,
        colorSecondary: Colors.white,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.DEFENDING_PLAYER_MARKING_POSSESSOR_HIGHLIGHT]: {
    priority: -1,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.white,
        colorSecondary: Colors.red,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.FINISHING_PASS_OVERCOMING_PASS_ARROW]: {
    priority: 1,
    glyphs: [
      {
        element: GlyphElement.ARROW,
        size: 0.1,
        colorPrimary: Colors.blue,
      },
    ],
  },
  [OverlayElementNames.FINISHING_PASS_PASSER_HIGHLIGHT]: {
    priority: 1,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.white,
        colorSecondary: Colors.blue,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.REALIZED_FINISHING_SUPPORT_HIGHLIGHT]: {
    priority: 2,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.blue,
        colorSecondary: Colors.blue,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.REALIZED_FINISHING_SUPPORT_SUPPORTING_PLAYER_PATH]: {
    priority: 2,
    glyphs: [
      {
        element: GlyphElement.SCATTER,
        size: 0.1,
        colorPrimary: Colors.blue,
      },
    ],
  },
  [OverlayElementNames.OVERCOME_PLAYER_HIGHLIGHT]: {
    priority: 3,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.peach,
        colorSecondary: Colors.peach,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.OVERCOMING_PASS_PASSER_HIGHLIGHT]: {
    priority: 3,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.white,
        colorSecondary: Colors.aqua,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.OVERCOMING_PASS_ARROW]: {
    priority: 3,
    glyphs: [
      {
        element: GlyphElement.ARROW,
        size: 0.1,
        colorPrimary: Colors.aqua,
      },
    ],
  },
  [OverlayElementNames.VERTICALLY_OVERCOMING_PASS_RECEIVER_HIGHLIGHT_SEMICIRCLE_LEFT]: {
    priority: 3,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.aqua,
        colorSecondary: Colors.aqua,
        style: 'semicircle-left',
      },
    ],
  },
  [OverlayElementNames.VERTICALLY_OVERCOMING_PASS_RECEIVER_HIGHLIGHT_SEMICIRCLE_RIGHT]: {
    priority: 3,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.aqua,
        colorSecondary: Colors.aqua,
        style: 'semicircle-right',
      },
    ],
  },
  [OverlayElementNames.REALIZED_VERTICAL_SUPPORT_SUPPORTING_PLAYER_PATH]: {
    priority: 3,
    glyphs: [
      {
        element: GlyphElement.SCATTER,
        size: 0.1,
        colorPrimary: Colors.aqua,
      },
    ],
  },
  [OverlayElementNames.HORIZONTALLY_OVERCOMING_PASS_RECEIVER_HIGHLIGHT_SEMICIRCLE_DOWN]: {
    priority: 5,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.white,
        colorSecondary: Colors.white,
        style: 'semicircle-down',
      },
    ],
  },
  [OverlayElementNames.HORIZONTALLY_OVERCOMING_PASS_RECEIVER_HIGHLIGHT_SEMICIRCLE_UP]: {
    priority: 5,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.white,
        colorSecondary: Colors.white,
        style: 'semicircle-up',
      },
    ],
  },
  [OverlayElementNames.REALIZED_HORIZONTAL_SUPPORT_SUPPORTING_PLAYER_PATH]: {
    priority: 5,
    glyphs: [
      {
        element: GlyphElement.SCATTER,
        size: 0.1,
        colorPrimary: Colors.white,
      },
    ],
  },
  [OverlayElementNames.EMERGENCY_PASS_PASSER_HIGHLIGHT]: {
    priority: 5,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.white,
        colorSecondary: Colors.red,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.EMERGENCY_PASS_RECEIVER_HIGHLIGHT]: {
    priority: 5,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.red,
        colorSecondary: Colors.red,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.STRIKER_EMERGENCY_PASS_SUPPORTER_HIGHLIGHT_SEMICIRCLE_UP]: {
    priority: 4,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.red,
        colorSecondary: Colors.red,
        style: 'semicircle-up',
      },
    ],
  },
  [OverlayElementNames.EMERGENCY_SUPPORT_SUPPORTING_PLAYER_PATH]: {
    priority: 4,
    glyphs: [
      {
        element: GlyphElement.SCATTER,
        size: 0.1,
        colorPrimary: Colors.red,
      },
    ],
  },
  [OverlayElementNames.RECEIVING_BETWEEN_LINES_CLOSEST_PLAYER_CIRCLE]: {
    priority: 0,
    glyphs: [
      {
        element: GlyphElement.CIRCLE_AREA,
        size: 1,
        colorPrimary: Colors.white,
        alpha: 0.3,
      },
    ],
  },
  [OverlayElementNames.PASSING_BETWEEN_LINES_PASSER_HIGHLIGHT]: {
    priority: 2,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.white,
        colorSecondary: Colors.blue,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.WIDE_PLAYER_HIGHLIGHT]: {
    priority: 7,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.white,
        colorSecondary: Colors.white,
        style: 'circle',
      },
      {
        element: GlyphElement.LINE,
        size: 0.1,
        colorPrimary: Colors.white,
      },
    ],
  },
  [OverlayElementNames.TEAM_PERIMETER]: {
    priority: -1,
    glyphs: [
      {
        element: GlyphElement.POLYLINE,
        size: 0.1,
        colorPrimary: Colors.orange,
      },
    ],
  },
  [OverlayElementNames.TEAM_PERIMETER_AFTER_RECOVERY]: {
    priority: -1,
    glyphs: [
      {
        element: GlyphElement.POLYLINE,
        size: 0.1,
        colorPrimary: Colors.blueRiver,
      },
    ],
  },
  [OverlayElementNames.TEAM_PERIMETER_AFTER_LOSS]: {
    priority: -1,
    glyphs: [
      {
        element: GlyphElement.POLYLINE,
        size: 0.1,
        colorPrimary: Colors.orange,
      },
    ],
  },
  [OverlayElementNames.DEFENSIVE_LINE_TO_HALFWAY_LINE_ARROWS]: {
    priority: 6,
    glyphs: [
      {
        element: GlyphElement.ARROW,
        size: 0.1,
        colorPrimary: Colors.white,
      },
    ],
  },
  [OverlayElementNames.RUN_BEHIND_DEFENSIVE_LINE]: {
    priority: 1,
    glyphs: [
      {
        element: GlyphElement.DASHED_LINE,
        size: 0.1,
        colorPrimary: Colors.blue,
      },
    ],
  },
  [OverlayElementNames.RUN_INTO_THE_BOX]: {
    priority: 1,
    glyphs: [
      {
        element: GlyphElement.DASHED_LINE,
        size: 0.1,
        colorPrimary: Colors.midnight,
      },
    ],
  },
  [OverlayElementNames.PLAYER_TAKING_ADVANTAGE_OF_DEFENSIVE_LINE_IMBALANCE_HIGHLIGHT]: {
    priority: 1,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.blue,
        colorSecondary: Colors.blue,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.NEUTRALIZING_OPPONENT_ADVANTAGE_OF_DEFENSIVE_LINE_IMBALANCE_ATTACKING_PLAYER_HIGHLIGHT]: {
    priority: 1,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.storm,
        colorSecondary: Colors.white,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.FINISHING_PLAYER_HIGHLIGHT]: {
    priority: 1,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.peach,
        colorSecondary: Colors.peach,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.LONG_BALL_PASS_ARROW]: {
    priority: 2,
    glyphs: [
      {
        element: GlyphElement.ARROW,
        size: 0.1,
        colorPrimary: Colors.aqua,
      },
    ],
  },
  [OverlayElementNames.LONG_BALL_PASSER_HIGHLIGHT]: {
    priority: 2,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.white,
        colorSecondary: Colors.aqua,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.FINISHING_AFTER_CROSS_PLAYER_HIGHLIGHT]: {
    priority: 1,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.peach,
        colorSecondary: Colors.aqua,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.GOAL_KICK_START_PASSER_HIGHLIGHT]: {
    priority: 1,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.peach,
        colorSecondary: Colors.aqua,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.GOAL_KICK_START_RECEIVER_HIGHLIGHT]: {
    priority: 1,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.peach,
        colorSecondary: Colors.aqua,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.GOAL_KICK_SECTION_HIGHLIGHT]: {
    priority: 1,
    glyphs: [
      {
        element: GlyphElement.POLYGON,
        size: 1,
        colorPrimary: Colors.aqua,
        alpha: 0.2,
      },
    ],
  },
  [OverlayElementNames.CROSS_PASSER_HIGHLIGHT]: {
    priority: 2,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.blue,
        colorSecondary: Colors.blue,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.CROSS_SAME_TEAM_TOUCH_ZONE_BORDER_HIGHLIGHT]: {
    priority: 1,
    glyphs: [
      {
        element: GlyphElement.POLYGON,
        size: 1,
        colorPrimary: Colors.white,
        alpha: 0.4,
      },
    ],
  },
  [OverlayElementNames.CROSS_OPPOSITION_TEAM_TOUCH_ZONE_BORDER_HIGHLIGHT]: {
    priority: 1,
    glyphs: [
      {
        element: GlyphElement.POLYGON,
        size: 1,
        colorPrimary: Colors.red,
        alpha: 0.2,
      },
    ],
  },
  [OverlayElementNames.FOUL_PLAYER_HIGHLIGHT]: {
    priority: 0,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.red,
        colorSecondary: Colors.white,
        style: 'circle_dashed',
      },
    ],
  },
  [OverlayElementNames.FOUL_CIRCLE_HIGHLIGHT]: {
    priority: 0,
    glyphs: [
      {
        element: GlyphElement.CIRCLE_AREA,
        size: 1,
        dynamic: 'lin',
        initial_size: 5,
        final_size: 15,
        colorPrimary: Colors.red,
        alpha: 0.3,
      },
    ],
  },
  [OverlayElementNames.PROGRESSION_PLAYER_HIGHLIGHT]: {
    priority: 0,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.blue,
        colorSecondary: Colors.white,
        style: 'circle_dashed',
      },
    ],
  },
  [OverlayElementNames.PROGRESSION_AFTER_RECOVERY_ARROW]: {
    priority: 0,
    glyphs: [
      {
        element: GlyphElement.ARROW_HIGHLIGHT,
        size: 8,
        colorPrimary: Colors.blue,
        colorSecondary: Colors.blue,
        style: 'solid',
        alpha: 0.5,
      },
    ],
  },
  [OverlayElementNames.POSSESSION_PLAYER_HIGHLIGHT]: {
    priority: 0,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.blue,
        colorSecondary: Colors.white,
        style: 'circle_dashed',
      },
    ],
  },
  [OverlayElementNames.POSSESSION_CIRCLE_HIGHLIGHT]: {
    priority: 0,
    glyphs: [
      {
        element: GlyphElement.CIRCLE_AREA,
        size: 1,
        dynamic: 'lin',
        initial_size: 5,
        final_size: 15,
        colorPrimary: Colors.blueRiver,
        alpha: 0.3,
      },
    ],
  },
  [OverlayElementNames.PRESSING_AFTER_LOSS_HIGHLIGHT]: {
    priority: 0,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.red,
        colorSecondary: Colors.red,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.HOLDING_AFTER_LOSS_HIGHLIGHT]: {
    priority: 0,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.white,
        colorSecondary: Colors.red,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.DEFENDER_PRESSING_POSSESSOR_HIGHLIGHT]: {
    priority: 1,
    glyphs: [
      {
        element: GlyphElement.HIGHLIGHT,
        size: 2,
        colorPrimary: Colors.red,
        colorSecondary: Colors.red,
        style: 'circle',
      },
    ],
  },
  [OverlayElementNames.MOVING_FORWARD_DURING_ORGANIZED_PRESSURE_ARROWS]: {
    priority: 3,
    glyphs: [
      {
        element: GlyphElement.DASHED_LINE,
        size: 0.1,
        colorPrimary: Colors.red,
      },
    ],
  },
  [OverlayElementNames.DEFENDING_RUN_BEHIND_DEFENSIVE_LINE]: {
    priority: 1,
    glyphs: [
      {
        element: GlyphElement.DASHED_LINE,
        size: 0.1,
        colorPrimary: Colors.red,
      },
    ],
  },
  [OverlayElementNames.DEFENDING_RUN_INTO_THE_BOX]: {
    priority: 1,
    glyphs: [
      {
        element: GlyphElement.DASHED_LINE,
        size: 0.1,
        colorPrimary: Colors.maroon,
      },
    ],
  },
  [OverlayElementNames.CLEAR_THE_BOX_DEFENDER_ARROW]: {
    priority: 0,
    glyphs: [
      {
        element: GlyphElement.DASHED_LINE,
        size: 0.1,
        colorPrimary: Colors.red,
      },
    ],
  },
  [OverlayElementNames.SECOND_BALL_OFFENSIVE_CONTROLLER_HIGHLIGHT]: {
    priority: 0,
    glyphs: [
      {
        element: GlyphElement.CIRCLE_AREA,
        size: 1,
        dynamic: 'lin',
        initial_size: 3,
        final_size: 3,
        colorPrimary: Colors.blue,
        alpha: 0.4,
      },
    ],
  },
  [OverlayElementNames.SECOND_BALL_DEFENSIVE_CONTROLLER_HIGHLIGHT]: {
    priority: 0,
    glyphs: [
      {
        element: GlyphElement.CIRCLE_AREA,
        size: 1,
        dynamic: 'lin',
        initial_size: 3,
        final_size: 3,
        colorPrimary: Colors.red,
        alpha: 0.3,
      },
    ],
  },
  [OverlayElementNames.PASS_BEHIND_DEFENSIVE_LINE_RECEIVER_HIGHLIGHT]: {
    priority: 0,
    glyphs: [
      {
        element: GlyphElement.CIRCLE_AREA,
        size: 1,
        colorPrimary: Colors.aqua,
        initial_size: 5,
        final_size: 5,
        alpha: 0.2,
      },
    ],
  },
  [OverlayElementNames.PASS_BEHIND_DEFENSIVE_LINE_ARROW]: {
    priority: 2,
    glyphs: [
      {
        element: GlyphElement.ARROW,
        size: 0.1,
        colorPrimary: Colors.aqua,
      },
    ],
  },
};

export type HomographyRow = [number, number, number];

export type Homography = [HomographyRow, HomographyRow, HomographyRow];

export type Size = {
  width: number; // Width in meters
  height: number; // Height in meters
};

export type PitchSize = {
  length: number; // Width in meters
  width: number; // Height in meters
};

export type Coordinates = { x: number; y: number };
