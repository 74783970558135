import Konva from 'konva';
import { Coordinates, OverlayElement } from '../../types';

import { Reference } from '../../utils/loaders';
import { ArrowHighlightGlyph } from '../glyphs/ArrowHighlightGlyph';
import { OverlayElementGlyphs, UpdatePositionOptions } from './interface';
import { adjustStaticCoordinatesByPitchSize, getStaticCoordinates, isFrameInRange } from './utils';

type Options = {
  config: OverlayElement;
  overlayElementTypeId: string;
  references: Reference[];
  startFrame: number;
  endFrame: number;
};

export class ArrowHighlight extends OverlayElementGlyphs {
  arrowHighlight: ArrowHighlightGlyph;
  overlayElementTypeId: string;
  arrowCoordinates: Coordinates[];
  references: Reference[];
  startFrame: number;
  endFrame: number;
  updated: boolean = false;
  priority: number;
  excludedPlayerIdsFromRender: string[] = [];
  isVisible: boolean = true;

  constructor({ config, endFrame, overlayElementTypeId, references, startFrame }: Options) {
    super();
    // TODO should we do this more explicit in config?
    const lineGlyphConfig = config.glyphs[0];

    this.arrowHighlight = new ArrowHighlightGlyph({
      colorPrimary: lineGlyphConfig.colorPrimary,
      opacity: lineGlyphConfig.alpha ?? 1,
      size: lineGlyphConfig.size,
    });
    this.overlayElementTypeId = overlayElementTypeId;
    this.references = references;
    this.arrowCoordinates = getStaticCoordinates(this.references);
    this.startFrame = startFrame;
    this.endFrame = endFrame;
    this.priority = config.priority;
  }

  addToLayer(layer: Konva.Layer) {
    layer.add(this.arrowHighlight.shape);
  }

  update({ playersPositions, scale, frame, pitchSize }: UpdatePositionOptions) {
    if (!isFrameInRange(frame, this) || this.updated) return;

    this.updated = true;

    this.arrowHighlight.update(adjustStaticCoordinatesByPitchSize(this.arrowCoordinates, pitchSize), scale);
  }

  removeFromLayer = () => {
    this.arrowHighlight.shape.destroy();
  };

  show = () => {
    this.arrowHighlight.shape.show();
    this.isVisible = true;
  };

  hide = () => {
    this.arrowHighlight.shape.hide();
    this.isVisible = false;
  };

  setExcludedPlayerIdsFromRender(references: string[]) {
    this.excludedPlayerIdsFromRender = references;
  }
}
