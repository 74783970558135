import Konva from 'konva';

import { Coordinates } from '../../types';

type Options = { colorPrimary: string; opacity: number; size: number; dashed?: boolean };

const line = new Konva.Line({
  lineCap: 'round',
  lineJoin: 'round',
  listening: false,
});

export class LineGlyph {
  shape: Konva.Line;
  size: number;
  isDashed: boolean;

  constructor({ colorPrimary, opacity, size, dashed }: Options) {
    this.isDashed = Boolean(dashed);
    this.size = size;
    const dashedOptions = dashed ? { dash: this.getDashedLinePoints(1) } : {};

    this.shape = line.clone({
      opacity: opacity,
      points: [],
      stroke: colorPrimary,
      strokeWidth: size,
      ...dashedOptions,
    });
  }

  update(coordinates: Coordinates[], scaleFactor: number) {
    this.shape.dash(this.getDashedLinePoints(scaleFactor));
    this.shape.strokeWidth(this.size * scaleFactor);
    this.shape.points(
      coordinates.reduce((acc, coordinate) => {
        return coordinate?.x ? [...acc, coordinate.x * scaleFactor, coordinate.y * scaleFactor] : acc;
      }, [] as number[]),
    );
  }

  private getDashedLinePoints(scaleFactor: number) {
    return this.isDashed ? [this.size * scaleFactor * 8, this.size * scaleFactor * 8] : [];
  }
}
