import { OverlayElementNames, overlayElementTypes } from '../../types';

import { OverlayElement } from '../../utils/loaders';
import { ArrowHighlight } from './ArrowHighlight';
import { PlayerCircleAreaHighlight } from './PlayerCircleAreaHighlight';
import { CircleClosestRivalHighlight } from './CircleClosestRivalHighlight';
import { DefensiveLinePlayerDepthHighlight } from './DefensiveLinePlayerDepthHighlight';
import { HighlightCirclePlayer } from './HighlightCirclePlayer';
import { OverlayElementGlyphs } from './interface';
import { LineHighlight } from './LineHighlight';
import { LineToHalfwayWithArrows } from './LineToHalfwayWithArrow';
import { LineWithArrow } from './LineWithArrow';
import { PolygonArea } from './PolygonArea';
import { ScatterLine } from './ScatterLine';
import { SemiCircleHighlight } from './SemiCircleHighlight';
import { TeamPerimeter } from './TeamPerimeter';
import { WidthPlayerHighlight } from './WidthPlayerHighlight';
import { StraightLineToEndPositionWithArrow } from './StraightLineToEndPositionWithArrow';
import { PlayerVerticalArrow } from './PlayersVerticalArrow';
import { PolyLineWithArrowCurrentPosition } from './PolyLineWithArrowCurrentPosition';
import { CircleAreaHighlight } from './CircleAreaHighlight';

export const overlayElementsFactory = (
  overlayElement: OverlayElement,
  imageInterface: unknown,
): OverlayElementGlyphs => {
  const options = {
    references: overlayElement.references,
    overlayElementTypeId: overlayElement.overlayElementTypeId,
    startFrame: overlayElement.startFrame,
    endFrame: overlayElement.endFrame,
    config: overlayElementTypes[overlayElement.overlayElementTypeId],
    imageInterface,
  };

  switch (overlayElement.overlayElementTypeId) {
    case OverlayElementNames.DEFENSIVE_LINE_PLAYER_DEPTH_HIGHLIGHT:
      return new DefensiveLinePlayerDepthHighlight(options);

    case OverlayElementNames.TEAM_PERIMETER_AFTER_LOSS:
    case OverlayElementNames.TEAM_PERIMETER:
    case OverlayElementNames.TEAM_PERIMETER_AFTER_RECOVERY:
      return new TeamPerimeter(options);

    case OverlayElementNames.PLAYER_TAKING_ADVANTAGE_OF_DEFENSIVE_LINE_IMBALANCE_HIGHLIGHT:
    case OverlayElementNames.NEUTRALIZING_OPPONENT_ADVANTAGE_OF_DEFENSIVE_LINE_IMBALANCE_ATTACKING_PLAYER_HIGHLIGHT:
    case OverlayElementNames.DEFENDING_PLAYER_MARKING_POSSESSOR_HIGHLIGHT:
    case OverlayElementNames.EMERGENCY_PASS_PASSER_HIGHLIGHT:
    case OverlayElementNames.EMERGENCY_PASS_RECEIVER_HIGHLIGHT:
    case OverlayElementNames.FINISHING_AFTER_CROSS_PLAYER_HIGHLIGHT:
    case OverlayElementNames.FINISHING_PASS_PASSER_HIGHLIGHT:
    case OverlayElementNames.FINISHING_PLAYER_HIGHLIGHT:
    case OverlayElementNames.GOAL_KICK_START_PASSER_HIGHLIGHT:
    case OverlayElementNames.GOAL_KICK_START_RECEIVER_HIGHLIGHT:
    case OverlayElementNames.LONG_BALL_PASSER_HIGHLIGHT:
    case OverlayElementNames.MARKED_ATTACKING_PLAYER_HIGHLIGHT:
    case OverlayElementNames.MARKER_DEFENDING_PLAYER_HIGHLIGHT:
    case OverlayElementNames.OVERCOME_PLAYER_HIGHLIGHT:
    case OverlayElementNames.OVERCOMING_PASS_PASSER_HIGHLIGHT:
    case OverlayElementNames.PASSING_BETWEEN_LINES_PASSER_HIGHLIGHT:
    case OverlayElementNames.REALIZED_FINISHING_SUPPORT_HIGHLIGHT:
    case OverlayElementNames.SWITCH_OF_PLAY_PASSER_HIGHLIGHT:
    case OverlayElementNames.SWITCH_OF_PLAY_RECEIVER_HIGHLIGHT:
    case OverlayElementNames.UNMARKED_ATTACKING_PLAYER_HIGHLIGHT:
    case OverlayElementNames.PROGRESSION_PLAYER_HIGHLIGHT:
    case OverlayElementNames.HOLDING_AFTER_LOSS_HIGHLIGHT:
    case OverlayElementNames.FOUL_PLAYER_HIGHLIGHT:
    case OverlayElementNames.CROSS_PASSER_HIGHLIGHT:
    case OverlayElementNames.POSSESSION_PLAYER_HIGHLIGHT:
    case OverlayElementNames.PRESSING_AFTER_LOSS_HIGHLIGHT:
    case OverlayElementNames.DEFENDER_PRESSING_POSSESSOR_HIGHLIGHT:
      return new HighlightCirclePlayer(options);

    case OverlayElementNames.GOAL_KICK_SECTION_HIGHLIGHT:
    case OverlayElementNames.CROSS_SAME_TEAM_TOUCH_ZONE_BORDER_HIGHLIGHT:
    case OverlayElementNames.CROSS_OPPOSITION_TEAM_TOUCH_ZONE_BORDER_HIGHLIGHT:
      return new PolygonArea(options);

    case OverlayElementNames.WIDE_PLAYER_HIGHLIGHT:
      return new WidthPlayerHighlight(options);

    case OverlayElementNames.DEFENSIVE_LINE_WIDTH_GAP_OK:
    case OverlayElementNames.DEFENSIVE_LINE_WIDTH_GAP_NOT_OK:
    case OverlayElementNames.MARKER_TO_MARKED_LINE:
      return new LineHighlight(options);

    case OverlayElementNames.OVERCOMING_PASS_ARROW:
    case OverlayElementNames.LONG_BALL_PASS_ARROW:
    case OverlayElementNames.SWITCH_OF_PLAY_PASS_ARROW:
    case OverlayElementNames.FINISHING_PASS_OVERCOMING_PASS_ARROW:
    case OverlayElementNames.PASS_BEHIND_DEFENSIVE_LINE_ARROW:
      return new LineWithArrow(options);

    case OverlayElementNames.MOVING_FORWARD_DURING_ORGANIZED_PRESSURE_ARROWS:
      return new StraightLineToEndPositionWithArrow(options);

    case OverlayElementNames.DEFENSIVE_LINE_TO_HALFWAY_LINE_ARROWS:
      return new LineToHalfwayWithArrows(options);

    case OverlayElementNames.CLEAR_THE_BOX_DEFENDER_ARROW:
      return new PlayerVerticalArrow(options);

    case OverlayElementNames.RUN_BEHIND_DEFENSIVE_LINE:
    case OverlayElementNames.DEFENDING_RUN_BEHIND_DEFENSIVE_LINE:
    case OverlayElementNames.RUN_INTO_THE_BOX:
    case OverlayElementNames.DEFENDING_RUN_INTO_THE_BOX:
      return new PolyLineWithArrowCurrentPosition(options);

    case OverlayElementNames.RECEIVING_BETWEEN_LINES_CLOSEST_PLAYER_CIRCLE:
      return new CircleClosestRivalHighlight(options);

    case OverlayElementNames.FOUL_CIRCLE_HIGHLIGHT:
    case OverlayElementNames.POSSESSION_CIRCLE_HIGHLIGHT:
    case OverlayElementNames.SECOND_BALL_OFFENSIVE_CONTROLLER_HIGHLIGHT:
    case OverlayElementNames.SECOND_BALL_DEFENSIVE_CONTROLLER_HIGHLIGHT:
      return new PlayerCircleAreaHighlight(options);

    case OverlayElementNames.REALIZED_FINISHING_SUPPORT_SUPPORTING_PLAYER_PATH:
    case OverlayElementNames.REALIZED_VERTICAL_SUPPORT_SUPPORTING_PLAYER_PATH:
    case OverlayElementNames.REALIZED_HORIZONTAL_SUPPORT_SUPPORTING_PLAYER_PATH:
    case OverlayElementNames.EMERGENCY_SUPPORT_SUPPORTING_PLAYER_PATH:
      return new ScatterLine(options);

    case OverlayElementNames.VERTICALLY_OVERCOMING_PASS_RECEIVER_HIGHLIGHT_SEMICIRCLE_LEFT:
    case OverlayElementNames.VERTICALLY_OVERCOMING_PASS_RECEIVER_HIGHLIGHT_SEMICIRCLE_RIGHT:
    case OverlayElementNames.HORIZONTALLY_OVERCOMING_PASS_RECEIVER_HIGHLIGHT_SEMICIRCLE_DOWN:
    case OverlayElementNames.HORIZONTALLY_OVERCOMING_PASS_RECEIVER_HIGHLIGHT_SEMICIRCLE_UP:
    case OverlayElementNames.STRIKER_EMERGENCY_PASS_SUPPORTER_HIGHLIGHT_SEMICIRCLE_UP:
      return new SemiCircleHighlight(options);

    case OverlayElementNames.PROGRESSION_AFTER_RECOVERY_ARROW:
      return new ArrowHighlight(options);

    case OverlayElementNames.PASS_BEHIND_DEFENSIVE_LINE_RECEIVER_HIGHLIGHT:
      return new CircleAreaHighlight(options);

    default:
      throw new Error(`Overlay element type ${overlayElement.overlayElementTypeId} is not supported`);
  }
};
