import Konva from 'konva';
import { GlyphElement, OverlayElement } from '../../types';

import { Reference } from '../../utils/loaders';
import { LineGlyph } from '../glyphs/LineGlyph';
import { OverlayElementGlyphs, UpdatePositionOptions } from './interface';
import { findPlayersCoordinates, getPlayerReferences, isFrameInRange } from './utils';

type Options = {
  config: OverlayElement;
  overlayElementTypeId: string;
  references: Reference[];
  startFrame: number;
  endFrame: number;
};

export class LineHighlight extends OverlayElementGlyphs {
  line: LineGlyph;
  overlayElementTypeId: string;
  playersIds: string[];
  references: Reference[];
  startFrame: number;
  endFrame: number;
  priority: number;
  excludedPlayerIdsFromRender: string[] = [];
  isVisible: boolean = true;

  constructor({ config, endFrame, overlayElementTypeId, references, startFrame }: Options) {
    super();
    // TODO should we do this more explicit in config?
    const lineGlyphConfig = config.glyphs[0];

    this.line = new LineGlyph({
      opacity: lineGlyphConfig.alpha ?? 1,
      size: lineGlyphConfig.size,
      colorPrimary: lineGlyphConfig.colorPrimary,
      dashed: lineGlyphConfig.element === GlyphElement.DASHED_LINE,
    });
    this.overlayElementTypeId = overlayElementTypeId;
    this.references = references;
    this.playersIds = getPlayerReferences(this.references);
    this.startFrame = startFrame;
    this.endFrame = endFrame;
    this.priority = config.priority;
  }

  addToLayer(layer: Konva.Layer) {
    layer.add(this.line.shape);
  }

  update({ playersPositions, scale, frame, pitchSize }: UpdatePositionOptions) {
    if (!isFrameInRange(frame, this)) return;

    const playersCoordinates = findPlayersCoordinates(this.playersIds, playersPositions[frame]);

    const excludedPlayerIds = this.excludedPlayerIdsFromRender;

    this.line.update(
      this.playersIds.filter((p) => !excludedPlayerIds.includes(p)).map((playerId) => playersCoordinates[playerId]),
      scale,
    );
  }

  removeFromLayer = () => {
    this.line.shape.destroy();
  };

  show = () => {
    this.line.shape.show();
    this.isVisible = true;
  };

  hide = () => {
    this.line.shape.hide();
    this.isVisible = false;
  };

  setExcludedPlayerIdsFromRender(references: string[]) {
    this.excludedPlayerIdsFromRender = references;
  }
}
