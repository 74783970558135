import { Colors } from 'kognia-ui';
import Konva from 'konva';
import { createCircle, createCircleDashed } from '../assets';

type Options = {
  playerId: string;
  colorPrimary: Colors;
  colorSecondary?: Colors;
  type: 'dashed' | 'circle';
  opacity: number;
  size: number;
  startX: number;
  startY: number;
  imageInterface: unknown;
};

export class HighlightGlyph {
  shape: Konva.Image;
  playerId: string;
  size: number;

  constructor({
    playerId,
    colorPrimary,
    colorSecondary,
    type = 'dashed',
    opacity,
    size,
    startX,
    startY,
    imageInterface,
  }: Options) {
    this.size = size;
    this.playerId = playerId;

    this.shape = new Konva.Image({
      opacity: opacity,
      width: size,
      height: size,
      x: startX,
      y: startY,
      image:
        type === 'dashed'
          ? createCircleDashed(colorPrimary, colorSecondary ?? colorPrimary, imageInterface)
          : createCircle(colorPrimary, colorSecondary ?? colorPrimary, imageInterface),
    });
  }

  update(x: number, y: number, scaleFactor: number) {
    this.updatePosition(x * scaleFactor, y * scaleFactor);
    this.updateSize(scaleFactor);
  }

  private updatePosition(x: number, y: number) {
    this.shape.offsetX(this.size / 2);
    this.shape.offsetY(this.size / 2);
    this.shape.x(x - this.size / 2);
    this.shape.y(y - this.size / 2);
  }

  private updateSize(scaleFactor: number) {
    this.shape.scaleX(scaleFactor);
    this.shape.scaleY(scaleFactor);
  }
}
